<template>
    <div>
        <head-layout :head-btn-options="headBtnOptions" head-title="作业票控件" @head-save-back="headSave('back')"
            @head-save="headSave('save')" @head-cancel="headCancel">
        </head-layout>
        <form-layout :column="column" :dataForm.sync="dataObj" ref="formLayout"></form-layout>
    </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import { ticketControlSubmit, ticketControlDetail } from "@/api/workTicket/index"
export default {
    components: { FormLayout, HeadLayout },
    computed: {
    },
    data() {
        return {
            headBtnOptions: [{
                label: this.$t('cip.cmn.btn.saveBtn'),
                emit: "head-save",
                type: "button",
                icon: ""
            },
            {
                label: this.$t('cip.cmn.btn.saveBackBtn'),
                emit: "head-save-back",
                type: "button",
                icon: ""
            }, {
                label: this.$t('cip.cmn.btn.celBtn'),
                emit: "head-cancel",
                type: "button",
                icon: ""
            }],
            dataObj: {
            },
            column: [
                {
                    label: '节点名称',
                    prop: "name",
                    maxlength: 50,
                    rules: [{ required: true, message: '请输入节点名称', trigger: ["blur", "change"] }]
                },
                {
                    label: '业务域',
                    prop: "domain",
                    type: 'select',
                    props: {
                        label: "label",
                        value: "value"
                    },
                    dicData: [
                        {
                            label: '文本',
                            value: 'text'
                        },
                        {
                            label: '用户',
                            value: 'user'
                        },
                        {
                            label: '部门',
                            value: 'dept'
                        },
                        {
                            label: '时间',
                            value: 'datePick'
                        },
                    ],
                    rules: [{ required: true, message: '请输入业务域', trigger: ["blur", "change"] }]
                },
                {
                    label: '字段名',
                    prop: "enName",
                    maxlength: 50,
                    rules: [{ required: true, message: '请输入字段名', trigger: ["blur", "change"] }]
                },
                {
                    label: '编码',
                    prop: "code",
                    maxlength: 50,
                },
                {
                    label: '长度',
                    prop: "len",
                    maxlength: 50,
                },
                {
                    label: '类型',
                    prop: "style",
                    maxlength: 50,
                },
            ],
        }
    },
    created() {
        let { type, id, category, pid } = this.$route.query
        this.type = type
        if (this.type == 'add') {
            this.dataObj.category = category
            this.dataObj.pid = 0
        }
        if (this.type == 'edit') {
            this.detail(id)
        }
        if (this.type == 'addChild') {
            this.dataObj.category = category
            this.dataObj.pid = pid
        }
    },
    mounted() { },

    methods: {
        detail(id) {
            ticketControlDetail(id).then(res => {
                if (res.data.code == 200) {
                    this.dataObj = res.data.data
                }
            })
        },
        headSave(type) {
            this.$refs.formLayout.$refs.form.validate(valid => {
                if (valid) {
                    this.$loading()
                    ticketControlSubmit(this.dataObj).then((res) => {
                        if (type == 'back') {
                            this.close()
                        }
                        this.dataObj = res.data.data
                        this.$message({
                            type: "success",
                            message: this.$t("cip.cmn.msg.success.operateSuccess"),
                        });
                    }).finally(() => {
                        this.$refs.formLayout.$refs.form.allDisabled = false
                        this.$loading().close()
                    })
                } else {
                    this.$refs.formLayout.$refs.form.allDisabled = false
                }


            })

        },
        close() {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
        },
        headCancel() {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
        },
    }
}
</script>

<style></style>